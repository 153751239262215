<a
  id="volume-button"
  #volumeButton
  mat-button
  [attr.aria-label]="'Volume (' + volumePercent + '%)'"
  title="Volume ({{volumePercent}}%)"
  id="open-volume"
  href="javascript:;"
  (click)="openSlider()"
  (keydown)="sliderButtonKey($event)"
  >
  @if (volume < 0.02) {
    <mat-icon>volume_mute</mat-icon>
  }
  @if (volume >= 0.02 && volume <= 0.6) {
    <mat-icon>volume_down</mat-icon>
  }
  @if (volume > 0.6) {
    <mat-icon>volume_up</mat-icon>
  }
</a>

<div role="slider" id="volume-slider" [class.active]="open" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100" [attr.aria-valuenow]="volumePercent" (keydown)="sliderButtonKey($event)">
  <div id="volume-slider-track">
    <div #volumeTrackReceiver id="volume-slider-receiver" (mousedown)="volumeSliderMouseDown($event)"></div>
    <div id="volume-slider-line"></div>
    <button role="button" #sliderButton id="volume-slider-button" [style.bottom]="(volume*100)+'%'" (mousedown)="volumeSliderMouseDown($event)" (keydown)="sliderButtonKey($event)">
      <div id="volume-slider-button-visual"></div>
    </button>
  </div>

  <a class="mute-button" mat-icon-button aria-label="Mute toggle button" href="javascript:;" (click)="toggleMute($event)" (keydown)="sliderButtonKey($event)">
    @if (muted) {
      @if (volume == 0) {
        <mat-icon>volume_off</mat-icon>
      }
    }
    @if (!muted) {
      @if (volume == 0) {
        <mat-icon>volume_off</mat-icon>
      }
      @if (volume > 0 && volume < 0.02) {
        <mat-icon>volume_mute</mat-icon>
      }
      @if (volume >= 0.02 && volume <= 0.6) {
        <mat-icon>volume_down</mat-icon>
      }
      @if (volume > 0.6) {
        <mat-icon>volume_up</mat-icon>
      }
    }
  </a>
</div>