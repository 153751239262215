import { CommonModule as ACommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TYTCommonModule } from '@tytapp/common';
import { UserModule } from '@tytapp/user';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { DevModule } from '@tytapp/app/dev';
import { ChatModule } from '@tytapp/chat/chat.module';
import { CommonUiModule } from '@tytapp/common-ui';
import { ContentBlocksService } from '@tytapp/content';
import { environment } from '@tytapp/environment';
import { AirplaySenderComponent } from '@tytapp/media-playback/airplay-sender/airplay-sender.component';
import { CastSenderComponent } from '@tytapp/media-playback/cast-sender/cast-sender.component';
import { AirplayButtonComponent } from './airplay-button/airplay-button.component';
import { AirPlayService } from './airplay.service';
import { CastButtonComponent } from './cast-button/cast-button.component';
import { NativeGoogleCastPlugin, OfficialGoogleCastPlugin } from './google-cast';
import { GoogleCastService } from './google-cast.service';
import { GoogleCastPlugin } from './google-cast/google-cast-plugin';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { MediaServiceRegistry } from './media-services';
import { MediaViewComponent } from './media-view/media-view.component';
import { NoJsMediaPlayerComponent } from './no-js-media-player/no-js-media-player.component';
import { Playback } from './playback';
import { PlayerBannerComponent } from './player-banner/player-banner.component';
import { PlayerSeekBarComponent } from './player-seek-bar/player-seek-bar.component';
import { PlayerVolumeButtonComponent } from './player-volume-button/player-volume-button.component';
import { PlayerComponent } from './player/player.component';
import { ProductionViewComponent } from './production-view/production-view.component';
import { RemoteAudioPlayerComponent } from './remote-audio-player/remote-audio-player.component';
import { ShowProductionViewComponent } from './show-production-view/show-production-view.component';
import { VIDEO_BLOCK_TYPE } from './video-block';
import { VideoPickerDialogComponent } from './video-picker/video-picker.component';
import { YtChatNativeMessageComponent } from './yt-chat-native-message/yt-chat-native-message.component';

const COMPONENTS = [
    PlayerComponent,
    PlayerSeekBarComponent,
    PlayerVolumeButtonComponent,
    ProductionViewComponent,
    ShowProductionViewComponent,
    RemoteAudioPlayerComponent,
    CastButtonComponent,
    CastSenderComponent,
    AirplaySenderComponent,
    AirplayButtonComponent,
    PlayerBannerComponent,
    MediaViewComponent,
    NoJsMediaPlayerComponent,
    LiveChatComponent,
    YtChatNativeMessageComponent,
    VideoPickerDialogComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        HttpClientModule,
        FormsModule,
        MatIconModule,
        ACommonModule,
        RouterModule,
        UserModule,
        TYTCommonModule,
        CommonUiModule,
        ChatModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        MatMenuModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatCheckboxModule,
        DevModule
    ],
    exports: COMPONENTS
})
export class MediaPlaybackModule {
    constructor() {
        ContentBlocksService.register(VIDEO_BLOCK_TYPE);
    }

    static forRoot(): ModuleWithProviders<MediaPlaybackModule> {
        return {
            ngModule: MediaPlaybackModule,
            providers: [
                MediaServiceRegistry,
                Playback,
                GoogleCastService,
                AirPlayService,
                {
                    provide: GoogleCastPlugin,
                    useClass: environment.isNativeBuild ? NativeGoogleCastPlugin : OfficialGoogleCastPlugin
                }
            ]
        };
    }
}
