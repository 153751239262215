import { inject, Injectable, Injector, Provider, StaticProvider } from '@angular/core';
import { LoggerService, sleep } from '@tytapp/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiVOD, ApiPodcast, ApiLiveStream, ApiProduction, ApiAvAsset } from '@tytapp/api';
import { Playback } from './playback';
import { Router } from '@angular/router';
import { ProductionsService } from '@tytapp/common-ui';
import { isServerSide } from '@tytapp/environment-utils';
import { CastPlaybackState, GoogleCastPlugin } from './google-cast/google-cast-plugin';
import { HostApi } from '@tytapp/common';
import { NativeGoogleCastPlugin } from './google-cast/native-google-cast-plugin';
import { OfficialGoogleCastPlugin } from './google-cast/official-google-cast-plugin';

export interface TYTCastMessage {
    type: string;
}

export interface TYTCastMediaChangedMessage extends TYTCastMessage {
    type: "mediaChanged";
    item: ApiVOD | ApiLiveStream | ApiPodcast;
    production: ApiProduction;
}

@Injectable()
export class GoogleCastService {
    private injector = inject(Injector);
    private hostApi = inject(HostApi);
    private logger = inject(LoggerService);

    private cast: GoogleCastPlugin;

    private _availableChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _connectedChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _playbackStateChanged: BehaviorSubject<CastPlaybackState> = new BehaviorSubject<CastPlaybackState>({
        playbackState: 'unstarted',
        duration: 0,
        currentTime: 0
    });
    private _connectingChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private playbackService = inject(Playback);
    private router = inject(Router);
    private productionsService = inject(ProductionsService);
    private initialized: boolean = false;

    /**
     * Set to true when the user explicitly ends the
     * session. When true, the media will autoplay upon
     * resuming local playback.
     */
    private allowResumeOnEnd = false;
    private _connected: boolean = false;

    private _fireReady: () => void;
    private _ready = new Promise<void>(r => this._fireReady = r);

    get supported() { return this.cast?.supported ?? false; }

    async initialize() {
        if (isServerSide())
            return;

        // Determine which Google Cast plugin we should use, depending on the current host capabilities.

        let castProvider: StaticProvider;
        if (await this.hostApi.hasCapability('media:google_cast_native')) {
            console.log(`[Google Cast] Using native cast provider`);
            castProvider = <StaticProvider>{ provide: GoogleCastPlugin, useClass: NativeGoogleCastPlugin };
        } else {
            console.log(`[Google Cast] Using SDK cast provider`);
            castProvider = <StaticProvider>{ provide: GoogleCastPlugin, useClass: OfficialGoogleCastPlugin };
        }

        this.cast = Injector.create({ providers: [ castProvider ], parent: this.injector }).get(GoogleCastPlugin);
        this.cast.initialize();

        this.logger.info(`[GoogleCast] initializing...`);

        this.cast.playbackStateChanged.subscribe(async playbackState => {
            this._playbackStateChanged.next(playbackState);
        })
        this.cast.sessionStateChanged.subscribe(async sessionState => {
            console.log(`[GoogleCast] Session state change: ${sessionState}`);

            if (sessionState === 'ended' || sessionState === 'ending') {
                this.playbackService.enableSessionTracking = true;
                this.playbackService.lockControlsVisible = false;
                return;
            }

            if (['resumed', 'started'].includes(sessionState)) {
                this.allowResumeOnEnd = false;
                this.playbackService.enableSessionTracking = false;
                this.playbackService.lockControlsVisible = true;

                let session = this.cast.session;
                let resuming = sessionState === 'resumed';

                session.messageReceived.subscribe(async message => {
                    if (message.type === 'mediaChanged') {
                        let mediaChangedMessage = <TYTCastMediaChangedMessage>message;
                        let item = mediaChangedMessage.item;
                        let production = mediaChangedMessage.production;

                        this.logger.info(`[GoogleCast]: Current media has changed to:`);
                        this.logger.inspect({ item, production });

                        if (!this.playbackService.isAppPlayerActive) {
                            let session = this.playbackService.currentPlayer.getCurrentPlaybackSession();

                            if (session['isCastRemoteSession'] && !session['isCurrentMedia']) {
                                if (item.type === 'live_stream') {
                                    this.logger.info(`[GoogleCast] Navigating to new live stream page:`);
                                    this.router.navigate([`/live/streams/${item.id}`]);

                                } else if (item.type === 'vod') {
                                    let vod = <ApiVOD>item;
                                    let playlistID = 'episodes';
                                    if (production.vod_clips.some(x => x.id === vod.id))
                                        playlistID = 'clips';

                                    this.logger.info(`[GoogleCast] Navigating to new media page:`);
                                    this.router.navigateByUrl(await this.productionsService.getWatchUrl(production, playlistID, vod));
                                }
                            }
                        }

                    }

                    this.logger.inspect(message);
                });

                if (resuming)
                    this.logger.info(`[GoogleCast]: Cast session resuming`);
                else
                    this.logger.info(`[GoogleCast]: Cast session started`);

                if (session.getMediaSession()) {
                    this.logger.info(`[GoogleCast]: Media is already playing back.`);
                } else {
                    this.logger.info(`[GoogleCast]: No initial media session.`);
                }

                let autoplayOnRemote = !resuming;

                if (this.playbackService.currentPlayer) {
                    let session = this.playbackService.currentPlayer.getCurrentPlaybackSession();

                    if (session && !session['isGoogleCastSession']) {
                        // Take it over!
                        this.logger.info(`[GoogleCast]: Switching to GoogleCast remote playback session...`);

                        let newSession = await this.playbackService.currentPlayer.refreshMediaResolution(!resuming);
                        this.logger.info(`[GoogleCast]: Re-resolved playback session to:`);
                        this.logger.inspect(newSession);
                    }
                }

                // session.mediaSessionChanged.subscribe(async mediaSession => {
                //     if (!this.playbackService.currentPlayer)
                //         return;

                //     let session = this.playbackService.currentPlayer.getCurrentPlaybackSession();
                //     let existingMediaId = undefined;

                //     if (!session)
                //         return;

                //     // TODO: this could be causing playstate jitter
                //     if (session.item.item.type === 'live_stream') {
                //         existingMediaId = `live:${session.item.item.id}`;
                //     } else {
                //         existingMediaId = `vod:${session.item.item.id}`;
                //     }

                //     if (session['isGoogleCastSession'])
                //         this.logger.info(`GoogleCast: Refreshing GoogleCast remote playback session for the new media session...`);

                //     let refreshAutoplay = autoplayOnRemote;
                //     autoplayOnRemote = false;

                //     let newSession = await this.playbackService.currentPlayer.refreshMediaResolution(refreshAutoplay);
                // });
            }
        });

        this.cast.castStateChanged.subscribe(async castState => {
            this.logger.info(`GoogleCast: State changed: ${castState}`);

            if (castState == "noDevicesAvailable") {
                this._availableChanged.next(false);
                this._connectingChanged.next(false);
                this._connectedChanged.next(false);
                this._connected = false;
            } else if (castState == "notConnected") {
                this._availableChanged.next(true);
                this._connectingChanged.next(false);
                this._connectedChanged.next(false);
                this._connected = false;
                this.playbackService.globalBuffering = false;
            } else if (castState == "connecting") {
                this._availableChanged.next(true);
                this._connectingChanged.next(true);
                this._connectedChanged.next(false);
                this._connected = true;
                this.playbackService.globalBuffering = true;

                let player = this.playbackService.currentPlayer;
                if (player) {
                    let session = player.getCurrentPlaybackSession();
                    if (session)
                        session.pause();
                }

            } else if (castState == "connected") {
                this._availableChanged.next(true);
                this._connectingChanged.next(false);
                this._connectedChanged.next(true);
                this._connected = true;
                this.playbackService.globalBuffering = false;
            }

            if (!this._connected && this.playbackService.currentPlayer) {

                // Sleep to allow the cast session to fully end before transitioning back.
                await sleep(1000);

                let session = this.playbackService.currentPlayer.getCurrentPlaybackSession();

                if (session?.['isGoogleCastSession']) {
                    // Take it back!

                    this.logger.info(`GoogleCast: Switching from GoogleCast playback session back to regular playback...`);

                    let isPlaying = !['paused', 'unstarted'].includes(session.playState);
                    let newSession = await this.playbackService.currentPlayer.refreshMediaResolution(isPlaying && this.allowResumeOnEnd);

                    this.logger.info(`GoogleCast: Re-resolved playback session to:`);
                    this.logger.inspect(newSession);
                }
            }
        });

        this._fireReady();
    }

    async loadMedia(asset: ApiAvAsset, position: number) {
        await this._ready;

        try {
            await this.cast.session.loadMedia({
                contentId: `${asset.provider}:${asset.url}`,
                contentType: 'video/mp4',
                currentTime: position
            });
        } catch (e) {
            throw new Error(`Failed to send loadMedia request: ${e}`);
        }

        // console.log(`Sending media change event`);
        // try {
        //     await this.cast.session.sendMessage({
        //         type: 'mediaChanged',
        //         production,
        //         item
        //     });
        // } catch (e) {
        //     console.error(`Failed to send mediaChanged event:`);
        //     console.error(e);
        // }
    }

    async castNow() {
        await this._ready;

        try {
            await this.cast.requestSession();
        } catch (e) {
            this.logger.info(`Failed to request session:`);
            debugger;
            this.logger.info(e);
        }
    }

    async stopCasting() {
        await this._ready;

        this.allowResumeOnEnd = true;
        console.log(`[GoogleCast] Ending current session.`);

        this.cast.endSession(true);
    }

    get currentSession() {
        return this.cast?.session;
    }

    get connected(): boolean {
        return this._connected;
    }

    get connectingChanged(): Observable<boolean> {
        return this._connectingChanged;
    }

    get availableChanged(): Observable<boolean> {
        return this._availableChanged;
    }

    get connectedChanged(): Observable<boolean> {
        return this._connectedChanged;
    }

    get playbackStateChanged() {
        return this._playbackStateChanged;
    }

    get playbackState() {
        return this.cast?.playbackState ?? {
            currentTime: 0,
            duration: 0,
            playbackState: 'unstarted'
        };
    }
}
